<template>
    <div style="height:100%;">
        <el-container style="height:100%;">
  <el-main style="height:100%; text-align: center;">
    <div style="
    margin-top: calc(50% - 500px);
    height:400px; width:400px; 
    margin-left: auto;
    margin-right: auto;

background-color: #ffffff33; border-radius: 16px;">
<div style="text-align: center;font-size: 22px; font-family: '黑体'; font-weight: bold; line-height: 36px;color:#ffffff; padding-top: 50px;">
    盈盈一水间·脉脉不得语
</div>
<div>
    <el-row style="margin-top: 40px">
        <el-col :span="6" align="right" style="color:#ffffff;">
用户名：
        </el-col>
        <el-col :span="18" align="left">
            <el-input v-model="username" type="text" style="width:180px"></el-input>
</el-col>
    </el-row>
    <el-row style="margin-top: 40px">
        <el-col :span="6" align="right" style="color:#ffffff;">
密码：
        </el-col>
        <el-col :span="18" align="left">
<el-input v-model="pwd" type="password" style="width:180px"></el-input>
</el-col>
    </el-row>
    <el-row style="margin-top: 40px">
        <el-col :span="6" align="right">
&nbsp;
        </el-col>
        <el-col :span="18" align="left">
            <el-button type="primary" style="width:120px; height: 50px; background-color: cadetblue; color:#ffffff;" @click="fnLogin">用户登录</el-button>
            <el-button type="primary" style="width:120px; height: 50px; background-color: cadetblue; color:#ffffff;" @click="fnPwd">忘记密码</el-button>
        </el-col>
    </el-row>
</div>
</div>
  </el-main>
  <el-footer>
    <a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备2022011776号-1</a>
  </el-footer>
</el-container>
    </div>
</template>
<script setup>
import { ElMessage } from 'element-plus';
import {ref} from 'vue'
const username=ref('')
const pwd=ref('')

const fnLogin=function(){
    if(!username.value){
        ElMessage.error("请输入登录用户名")
        return;
    }
    if(!pwd.value){
        ElMessage.error("请输入密码")
        return;
    }

    ElMessage.error("用户名或密码不正确")
    return;
}

const fnPwd=function(){
    ElMessage.error("忘记密码请与管理员联系")
}
</script>