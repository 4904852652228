<template>
<loginPart></loginPart>
</template>

<script>
import loginPart from './components/loginPart.vue'
export default {
  name: 'App',
  components: {
    loginPart
  }
}
</script>

<style>
.el-container,.el-main{
  width:100%;
  height:100% !important;
}
html,body{
  width:100%;
  height:100%;
  margin:0px;
  padding: 0px;
}
#app {
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  object-fit: covers;
  height:100%;
  width:100%;
  background-image:url(./assets/1.jpeg)
}
</style>
